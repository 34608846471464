/* Colors */
$Black-color: #000000;
$White-color: #ffffff;
$light-color-12: #f4f4f9;
$light-color-13: #e9e9e9;
$light-color-14: #faf8ff;
$light-color-15: #f4f8ff;
$light-color-16: #f5f5ff;
$light-green-color: #8ac539;
$purple-color-1: #e0cdff;
$blue-color-1: #00145a;

/*Common color code in entire frontend*/
$primary-color-3: #745afd;
$light-color: #f8f9fa;
$light-color-2: #d6d6ed;
$light-color-3: #eeeeee;
$light-Color-6: #e4e4e4;
$backgroundColor-light-6: #f8f8f8;
$primary-color-4: #0473ea;
$dark-color-5: #686868;
$skyBlue-background: #58c5ec;
$dark-color: #2e2e2e;
$border-color-20: #cecaff;
$light-Color-4: #dadada;
$primary-color-2: #795bff;
$light-color-7: #f5f2ff;

/*Border-radius*/
$border-radius1: 12px;
$border-radius2: 22px;
$border-radius3: 10.305px;
$border-radius4: 14px;
$border-radius5: 5px;

/* Linear gradient */
$Linear-Gradient: linear-gradient(97deg,
    $White-color 3.33%,
    $White-color 96.97%);
$Linear-Gradient2: linear-gradient(180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%);
$Linear-Gradient3: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
$Linear-Gradient4: linear-gradient(97.98deg, #675afd -2.83%, #8a5bff 106.88%);
$Linear-Gradient5: linear-gradient(242deg,
    #45108a 2.27%,
    #3d065f 29.57%,
    #10054d 101.35%);
$Navbar-Gradient: linear-gradient(98deg,
    #675afd -2.83%,
    #8a5bff 106.88%);


/*Box Shadow*/
$Box-Shadow1: 0px 37px 84px 0px rgba(209, 209, 209, 0.33) !important;
$Box-Shadow2: 0px 11px 18px 0px rgba(0, 0, 0, 0.05);
$Box-Shadow3: 0px 31.14536px 67.78696px 0px rgba(0, 0, 0, 0.07);
$Box-Shadow4: 30px 44px 54px rgba(0, 0, 0, 0.15);
$Box-Shadow5: 0px 21px 44px 0px rgba(0, 0, 0, 0.08);
$Text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);

/* MIXIN Background-Size */
@mixin background-size($size) {
  background-size: $size !important;
  -webkit-background-size: $size !important;
  -moz-background-size: $size !important;
  -o-background-size: $size !important;
}

@mixin displayFlex($alignItems: stretch,
  $justifyContent: flex-start,
  $flexDirection: row) {
  display: flex !important;
  align-items: $alignItems !important;
  justify-content: $justifyContent !important;
  flex-direction: $flexDirection !important;
}

/* Common Font Family */
@mixin variableFontFamily($fontWeight) {
  font-family: "ClashDisplay-Variable" !important;
  font-style: normal !important;
  font-weight: $fontWeight !important;
}

@mixin extraLightFontFamily {
  font-family: "ClashDisplay-Extralight" !important;
  font-style: normal !important;
  font-weight: 200 !important;
}

@mixin lightFontFamily {
  font-family: "ClashDisplay-Light" !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

@mixin regularFontFamily {
  font-family: "ClashDisplay-Regular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

@mixin mediumFontFamily {
  font-family: "ClashDisplay-Medium" !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

@mixin semiboldFontFamily {
  font-family: "ClashDisplay-Semibold" !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

@mixin boldFontFamily {
  font-family: "ClashDisplay-Bold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

/* Common Poppins Font Family */
@mixin poppinsVariableFontFamily($fontWeight) {
  font-family: "Poppins-Variable" !important;
  font-style: normal !important;
  font-weight: $fontWeight !important;
}

@mixin poppinsExtraLightFontFamily {
  font-family: "Poppins-Extralight" !important;
  font-style: normal !important;
  font-weight: 200 !important;
}

@mixin poppinsLightFontFamily {
  font-family: "Poppins-Light" !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

@mixin poppinsRegularFontFamily {
  font-family: "Poppins-Regular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

@mixin poppinsMediumFontFamily {
  font-family: "Poppins-Medium" !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

@mixin poppinsSemiboldFontFamily {
  font-family: "Poppins-Semibold" !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

@mixin poppinsBoldFontFamily {
  font-family: "Poppins-Bold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
}